import { Divider, Stack, Typography } from "@mui/joy";

type Operation = "ADD" | "SUBTRACT" | "MULTIPLY" | "DIVIDE";

export const BreakdownListItem = ({
	title,
	data,
	operation,
	divider,
}: {
	title: string;
	data: string;
	operation?: Operation;
	divider?: boolean;
}) => {
	let _operation = null;

	switch (operation) {
		case "ADD":
			_operation = "+";
			break;
		case "SUBTRACT":
			_operation = "-";
			break;
		case "MULTIPLY":
			_operation = "x";
			break;
		case "DIVIDE":
			_operation = "÷";
			break;
		default:
			_operation = null;
	}

	return (
		<Stack direction={"row"} justifyContent={"space-between"}>
			<Typography level="body-sm">{title}</Typography>
			{operation ? (
				<Stack marginLeft={2}>
					<Stack direction={"row"} justifyContent={"end"} marginBottom={1}>
						<Typography level="body-lg" marginRight={3} marginTop={"-3px"}>
							{_operation}
						</Typography>
						<Typography level="body-sm">{data}</Typography>
					</Stack>
					{divider && <Divider />}
				</Stack>
			) : (
				<Stack marginBottom={1}>
					<Typography level="body-sm">{data}</Typography>
				</Stack>
			)}
		</Stack>
	);
};
