import {
	Modal,
	ModalDialog,
	ModalClose,
	Typography,
	Input,
	Button,
	Box,
	Stack,
	Alert,
} from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { encodeStateToUrl } from "../encodeUrl";
import { useState } from "react";

const ShareModal = ({
	open,
	onClose,
}: {
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput } = useCalculatorContext();
	const [showAlert, setShowAlert] = useState(false);
	const url = encodeStateToUrl({ calculatorState: calculatorInput });

	const shareUrl = `https://kiwi-estate.vercel.app/${url}`;

	return (
		<Modal
			open={open}
			onClose={() => {
				onClose();
				setShowAlert(false);
			}}
		>
			<ModalDialog
				variant="soft"
				size="md"
				maxWidth={500}
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Stack marginTop={2}>
					<Typography level="body-md" marginBottom={1}>
						Share this link with someone to show them your report!
					</Typography>
					<Typography level="body-sm">
						We don't save any of your data. All your numbers that you've
						inputted are encoded into the url that is sent. It is then decoded
						when opening it, which allows the transfer of information.
					</Typography>

					<Box marginTop={1}>
						<Input
							value={shareUrl}
							disabled
							size="md"
							style={{ marginTop: 10, border: "1px solid #9FA6AD" }}
						/>
						<Stack direction="row" marginTop={2}>
							<Button
								onClick={() => {
									navigator.clipboard.writeText(shareUrl);
									setShowAlert(true);
								}}
							>
								Copy URL
							</Button>
							{showAlert && <Alert>Copied to Clipboard!</Alert>}
						</Stack>
					</Box>
					{/* <Typography
						level="body-md"
						textAlign={"center"}
						style={{ margin: "10px 0" }}
					>
						OR
					</Typography>
					<Typography level="body-md">
						Create a printaple version to e-mail or print
					</Typography>
					<Button
						onClick={() =>
							setCalculatorInput({ ...calculatorInput, printableMode: true })
						}
						style={{ marginTop: 10 }}
					>
						Create Printable Version
					</Button> */}
				</Stack>
			</ModalDialog>
		</Modal>
	);
};

export default ShareModal;
