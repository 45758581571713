import { Calculator } from "./CalculatorContext";
import pako from "pako";

export const encodeStateToUrl = ({
	calculatorState,
}: {
	calculatorState: Calculator;
}): string => {
	// Function to encode state into URL

	const queryParams = new URLSearchParams();
	// Encode each key-value pair from appState
	Object.entries(calculatorState).forEach(([key, value]) => {
		if (key === "printableMode" || typeof value === "boolean") {
			return;
		}
		if (typeof value === "number") {
			queryParams.append(key, value.toString());
		} else {
			queryParams.append(key, value);
		}
	});

	const compressedData = pako.deflate(queryParams.toString(), { level: 9 });
	const compressedBase64 = btoa(
		String.fromCharCode.apply(null, Array.from(compressedData))
	);
	return compressedBase64;
};

export const decodeUrlToState = ({
	pathname,
}: {
	pathname: string;
}): Calculator => {
	const decompressedData = new Uint8Array(
		atob(pathname)
			.split("")
			.map((char) => char.charCodeAt(0))
	);

	const calculatorData = pako.inflate(decompressedData, { to: "string" });
	const result = calculatorData;

	const keyValuePairs = result.split("&");

	// @ts-ignore
	const stateObject: Calculator = {};
	keyValuePairs.forEach((pair) => {
		const [key, value] = pair.split("=");
		// @ts-ignore
		stateObject[key] =
			key === "loanTerm"
				? decodeURIComponent(value)
				: Number(decodeURIComponent(value)); // Use decodeURIComponent to handle special characters
	});
	return stateObject;
};
