import { Modal, ModalDialog, ModalClose, Typography } from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { formatDollar } from "../printable/PrintableState";
import { BreakdownListItem } from "./BreakdownListItem";

export const CashEarnedOnSaleBreakdownModal = ({
	year,
	open,
	onClose,
}: {
	year: number;
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput, breakdown } = useCalculatorContext();

	if (year === 0) return null;

	const { purchasePrice, closingCostAmount } = calculatorInput;
	const {
		equityAccumulated,
		costsToSellAmount,
		accumulatedAnnualCashFlow,
		houseValue,
		cashAfterSell,
	} = breakdown[year - 1];

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">Cash Earned on Sale @ Year {year}</Typography>
				<BreakdownListItem
					title="Equity Accumulated"
					data={formatDollar(equityAccumulated)}
				/>
				<BreakdownListItem
					title="Home Appreciation Value"
					data={formatDollar(houseValue - purchasePrice)}
				/>
				<BreakdownListItem
					title="Accumulated Annual Cash Flow"
					data={formatDollar(accumulatedAnnualCashFlow)}
					operation="ADD"
				/>
				<BreakdownListItem
					title="Selling Costs"
					data={formatDollar(costsToSellAmount)}
				/>
				<BreakdownListItem
					title="Purchase Costs"
					data={formatDollar(closingCostAmount)}
					operation="SUBTRACT"
					divider
				/>
				<BreakdownListItem
					title="Cash Earned on Sale"
					data={formatDollar(cashAfterSell)}
				/>
			</ModalDialog>
		</Modal>
	);
};
