export const InfoText = {
	purchaseData: {
		purchasePrice: {
			title: "Purchase Price",
			description:
				"The **purchase price** of a home refers to the total amount of money agreed upon between the buyer and the seller for the sale of the property. ",
		},
		downPayment: {
			title: "Down Payment",
			description: `A **down payment** is the initial payment made when buying a home, typically a percentage of the total price.
			\n\nThis payment is essential for obtaining a mortgage and usually, the larger the down payment, the lower the monthly mortgage payments.
			\n\nIt reflects the buyer's financial commitment and reduces the lender's risk in the home-buying process.`,
		},
		loanTerm: {
			title: "Loan Term",
			description: `The **loan term** refers to the duration over which a borrower agrees to repay a mortgage loan.\n\nIt is typically expressed in years and can range from 15 to 30 years or more. The loan term, along with the interest rate, influences the amount of the borrower's monthly mortgage payments. Shorter loan terms generally result in higher monthly payments but lower overall interest costs, while longer loan terms often have lower monthly payments but may result in higher overall interest payments over the life of the loan. 
		\n\nBorrowers choose a loan term based on their financial goals, budget, and preferences.`,
		},
		interestRate: {
			title: "Interest Rates",
			description: `The interest rate on a mortgage is the annual cost, expressed as a percentage, that a borrower pays to the lender for using their funds.
			\n\nIt greatly affects the total interest paid over the loan's life, thereby influencing the overall cost of owning a home.`,
		},
		closingCosts: {
			title: "Closing Costs",
			description:
				"Fees and expenses at sale closure, usually 2-5% of purchase price, often paid by the buyer.",
		},
		principleAndInterest: {
			title: "Principal and Interest",
			description:
				"**Principal:** The borrowed amount for home purchase, reduced over time with mortgage payments.\n\n**Interest:** The cost of borrowing, charged as a percentage of the loan balance. Early payments are interest-heavy, shifting towards principal reduction over time.",
		},
		amortizationSchedule: {
			title: "Amortization Schedule",
			description:
				"A breakdown of mortgage payments over time, showing principal and interest allocation. Initially, payments are more interest-focused, gradually shifting towards principal reduction. Useful for understanding loan repayment and long-term homeownership costs.",
		},
	},
	otherExpenses: {
		hoaFees: {
			title: "HOA Fees",
			description:
				"Regular payments for community space maintenance, common in shared living spaces.",
		},
		propertyTax: {
			title: "Property Tax",
			description: "Taxes paid on property value, funding local services.",
		},
		insuranceFee: {
			title: "Home Insurance",
			description:
				"Covers losses/damages to the house and assets, often required by lenders.",
		},
		maintenanceFee: {
			title: "Maintenance Fees",
			description:
				"Payments for communal space upkeep, common in shared living spaces.",
		},
	},
	rentalData: {
		rentalIncome: {
			title: "Rental Income",
			description:
				"Income from leasing property, important for real estate investment.",
		},
	},
	saleData: {
		appreciationRate: {
			title: "Home Appreciation",
			description:
				"Percentage increase in property value over time, varies by location.",
		},
		costToSell: {
			title: "Cost to Sell",
			description:
				"Fees for finalizing a real estate transaction, typically 2-5% of sale price.",
		},
	},
	breakdownTable: {
		cashFlow: {
			title: "Cash Flow",
			description: "Income after expenses and mortgage payments.",
		},
		equityAccumulated: {
			title: "Equity Accumulated",
			description: "Property ownership value, purchase price minus loan paid.",
		},
	},
};
