import { Typography, Stack, Box, Grid } from "@mui/joy";
import { useCalculatorContext } from "./CalculatorContext";
import DollarInput from "./DollarInput";
import PercentInput from "./PercentInput";
import { InfoText } from "./InfoText";

const OtherExpensesInput = () => {
	const { calculatorInput, setCalculatorInput } = useCalculatorContext();
	const {
		hoaFees,
		propertyTax,
		hoaFeesAnnualIncrease,
		propertyTaxAnnualIncrease,
		otherExpenses,
		otherExpensesAnnualIncrease,
		insuranceFee,
		insuranceFeeAnnualIncrease,
		maintenanceFee,
		maintenanceFeeAnnualIncrease,
	} = calculatorInput;

	const total =
		hoaFees + propertyTax + otherExpenses + maintenanceFee + insuranceFee;
	return (
		<Box marginY={4}>
			<Typography level="h4">Other Expenses</Typography>
			<Grid
				container
				columns={{ xs: 6, md: 12 }}
				marginTop={1}
				columnSpacing={2}
				rowSpacing={1}
			>
				<Grid xs={6}>
					<Stack flexDirection={"row"}>
						<Box flex={2} marginRight={"6px"}>
							<DollarInput
								label="HOA Fees /month"
								name="hoaFees"
								value={hoaFees}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										hoaFees: Number(e.target.value),
									});
								}}
								info={InfoText.otherExpenses.hoaFees}
							/>
						</Box>
						<Box flex={1}>
							<PercentInput
								label="Annual Increase"
								name="hoaFeesAnnualIncrease"
								value={hoaFeesAnnualIncrease}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										hoaFeesAnnualIncrease: Number(e.target.value),
									});
								}}
							/>
						</Box>
					</Stack>
				</Grid>
				<Grid xs={6}>
					<Stack flexDirection={"row"}>
						<Box flex={2} marginRight={"6px"}>
							<DollarInput
								label="Property Tax /month"
								name="propertyTax"
								value={propertyTax}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										propertyTax: Number(e.target.value),
									});
								}}
								info={InfoText.otherExpenses.propertyTax}
							/>
						</Box>
						<Box flex={1}>
							<PercentInput
								label="Annual Increase"
								name="propertyTaxAnnualIncrease"
								value={propertyTaxAnnualIncrease}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										propertyTaxAnnualIncrease: Number(e.target.value),
									});
								}}
							/>
						</Box>
					</Stack>
				</Grid>
				<Grid xs={6}>
					<Stack flexDirection={"row"}>
						<Box flex={2} marginRight={"6px"}>
							<DollarInput
								label="Insurance /month"
								name="insuranceFee"
								value={insuranceFee}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										insuranceFee: Number(e.target.value),
									});
								}}
								info={InfoText.otherExpenses.insuranceFee}
							/>
						</Box>
						<Box flex={1}>
							<PercentInput
								label="Annual Increase"
								name="insuranceFeeAnnualIncrease"
								value={insuranceFeeAnnualIncrease}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										insuranceFeeAnnualIncrease: Number(e.target.value),
									});
								}}
							/>
						</Box>
					</Stack>
				</Grid>
				<Grid xs={6}>
					<Stack flexDirection={"row"}>
						<Box flex={2} marginRight={"6px"}>
							<DollarInput
								label="Maintenance /month"
								name="maintenanceFee"
								value={maintenanceFee}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										maintenanceFee: Number(e.target.value),
									});
								}}
								info={InfoText.otherExpenses.maintenanceFee}
							/>
						</Box>
						<Box flex={1}>
							<PercentInput
								label="Annual Increase"
								name="maintenanceFeeAnnualIncrease"
								value={maintenanceFeeAnnualIncrease}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										maintenanceFeeAnnualIncrease: Number(e.target.value),
									});
								}}
							/>
						</Box>
					</Stack>
				</Grid>
				<Grid xs={6}>
					<Stack flexDirection={"row"}>
						<Box flex={2} marginRight={"6px"}>
							<DollarInput
								label="Other Expenses /month"
								name="otherExpenses"
								value={otherExpenses}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										otherExpenses: Number(e.target.value),
									});
								}}
							/>
						</Box>
						<Box flex={1}>
							<PercentInput
								label="Annual Increase"
								name="otherExpensesAnnualIncrease"
								value={otherExpensesAnnualIncrease}
								onChange={(e) => {
									setCalculatorInput({
										...calculatorInput,
										otherExpensesAnnualIncrease: Number(e.target.value),
									});
								}}
							/>
						</Box>
					</Stack>
				</Grid>
			</Grid>
			<Typography level="title-md" marginTop={2}>
				Total Expenses: ${total.toFixed(2)}/month
			</Typography>
		</Box>
	);
};

export default OtherExpensesInput;
