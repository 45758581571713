import { AmortizationScheduleType } from "./AmortizationSchedule";
import { BreakdownType } from "./breakdown/Breakdown";
import { Calculator } from "./CalculatorContext";

export const nf = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});
export const calculateMortgage = ({
	principal,
	loanTerm,
	rate,
}: {
	principal: number; // Principle loan amount
	loanTerm: string; // # of years in loan term
	rate: number; // Monthly interest rate
}) => {
	let years = 0;
	if (loanTerm === "30-fixed") {
		years = 30;
	} else if (loanTerm === "15-fixed") {
		years = 15;
	}

	const monthlyRate = rate / 100 / 12;
	const n = years * 12;
	const monthlyPayment =
		(principal * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -n));

	return monthlyPayment;
};

export const calculateAmortization = ({
	principal,
	loanTerm,
	rate,
	monthlyPayment,
}: {
	principal: number; // Principle loan amount
	loanTerm: string; // # of years in loan term
	rate: number; // Monthly interest rate
	monthlyPayment: number;
}): AmortizationScheduleType[] => {
	let years = 0;
	if (loanTerm === "30-fixed") {
		years = 30;
	} else if (loanTerm === "15-fixed") {
		years = 15;
	}

	const monthlyRate = rate / 100 / 12;
	const n = years * 12; // # of months in loan term

	let balance = principal;
	let amortizationScheduleMonthly = [];
	let amortizationScheduleYearly = [];
	let accumulatedPrincipalPaid = 0;

	for (let i = 0; i < n; i++) {
		const interest = balance * monthlyRate;
		const principalPaid = monthlyPayment - interest;
		balance -= principalPaid;

		amortizationScheduleMonthly.push({
			month: i + 1,
			balance: balance,
			principalPaid: principalPaid,
			interestPaid: interest,
			totalInterestPaid: interest * (i + 1),
		});

		// Check if a year has passed
		if ((i + 1) % 12 === 0) {
			const totalYearlyInterest = amortizationScheduleMonthly.reduce(
				(total, month) => total + month.interestPaid,
				0
			);

			const totalPrincipalPaid = amortizationScheduleMonthly.reduce(
				(total, month) => total + month.principalPaid,
				0
			);

			accumulatedPrincipalPaid += totalPrincipalPaid;

			amortizationScheduleYearly.push({
				year: Math.floor((i + 1) / 12),
				totalInterestPaid: totalYearlyInterest,
				principalPaid: totalPrincipalPaid,
				accumulatedPrincipalPaid,
				balance: balance,
				monthlyPayments: amortizationScheduleMonthly,
			});

			amortizationScheduleMonthly = [];
		}
	}

	return amortizationScheduleYearly;
};

// Calculate breakdown of equity accumulation, and return on investment
export const calculateBreakdown = ({
	amortizationSchedule,
	appreciationRate,
	calculatorData,
}: {
	calculatorData: Calculator;
	appreciationRate: number;
	amortizationSchedule: AmortizationScheduleType[];
}): BreakdownType[] => {
	const {
		downPaymentAmount,
		purchasePrice,
		rentalIncome,
		hoaFees,
		hoaFeesAnnualIncrease,
		propertyTax,
		propertyTaxAnnualIncrease,
		maintenanceFee,
		maintenanceFeeAnnualIncrease,
		insuranceFee,
		insuranceFeeAnnualIncrease,
		otherExpenses,
		otherExpensesAnnualIncrease,
		rentalIncomeIncrease,
		mortgagePaymentAmount,
		costToSell,
		closingCostAmount,
	} = calculatorData;
	let accumulatedAnnualCashFlow = 0;
	return amortizationSchedule.map((yearData) => {
		const { year, principalPaid, accumulatedPrincipalPaid } = yearData;

		const annualRentalIncome =
			rentalIncome * Math.pow(1 + rentalIncomeIncrease / 100, year - 1);

		const annualCashFlow =
			(annualRentalIncome -
				mortgagePaymentAmount -
				hoaFees * Math.pow(1 + hoaFeesAnnualIncrease / 100, year - 1) -
				maintenanceFee *
					Math.pow(1 + maintenanceFeeAnnualIncrease / 100, year - 1) -
				insuranceFee *
					Math.pow(1 + insuranceFeeAnnualIncrease / 100, year - 1) -
				otherExpenses *
					Math.pow(1 + otherExpensesAnnualIncrease / 100, year - 1) -
				propertyTax * Math.pow(1 + propertyTaxAnnualIncrease / 100, year - 1)) *
			12;

		accumulatedAnnualCashFlow += annualCashFlow;
		const equityAccumulated = downPaymentAmount + accumulatedPrincipalPaid;

		const houseValue =
			purchasePrice * Math.pow(1 + appreciationRate / 100, year - 1);

		const appreciationAmount = houseValue - purchasePrice;

		const costsToSellAmount = houseValue * (costToSell / 100);
		const cashAfterSell =
			equityAccumulated +
			appreciationAmount +
			accumulatedAnnualCashFlow -
			costsToSellAmount -
			closingCostAmount;

		const baseROI = annualCashFlow / downPaymentAmount;
		return {
			year,
			principalPaid,
			baseROI,
			equityAccumulated,
			houseValue,
			annualCashFlow,
			accumulatedAnnualCashFlow,
			costsToSellAmount,
			cashAfterSell,
		};
	});
};
