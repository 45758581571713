import { Grid, Stack, Typography } from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { NumericFormat } from "react-number-format";
import AmorizationSchedule from "../AmortizationSchedule";
import OtherExpensesInput from "../OtherExpensesInput";
import Breakdown from "../breakdown/Breakdown";
import { DataRow } from "./DataRow";
export const formatDollar = (amount: number) => {
	const formattedAmount = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	}).format(amount);
	return formattedAmount;
};
export const PrintableState = () => {
	const { calculatorInput, setCalculatorInput } = useCalculatorContext();
	const {
		purchasePrice,
		downPaymentAmount,
		downPaymentPercent,
		closingCostAmount,
		closingCostPercent,
		interestRate,
		loanTerm,
		mortgagePaymentAmount,

		hoaFees,
		hoaFeesAnnualIncrease,
		propertyTax,
		propertyTaxAnnualIncrease,
		insuranceFee,
		maintenanceFee,
		otherExpenses,
		otherExpensesAnnualIncrease,
		rentalIncome,
		appreciationRate,
		costToSell,
	} = calculatorInput;
	const expenseTotal = hoaFees + propertyTax + otherExpenses;

	return (
		<>
			<Typography level="title-lg">Purchase Data</Typography>
			<Grid container marginBottom={3}>
				<Grid xs={6}>
					<DataRow label="Purchase Price" value={formatDollar(purchasePrice)} />
					<DataRow
						label="Down Payment"
						value={formatDollar(downPaymentAmount)}
					/>
					<DataRow
						label="Down Payment %"
						value={`${downPaymentPercent.toString()}%`}
					/>
					<DataRow
						label="Mortgage Payment (Principle & Interest)"
						value={formatDollar(mortgagePaymentAmount)}
					/>
				</Grid>
				<Grid xs={6}>
					<DataRow
						label="Interest Rate"
						value={`${interestRate.toString()}%`}
					/>
					<DataRow label="Loan Term" value={loanTerm.toString()} />
					<DataRow
						label="Closing Costs"
						value={formatDollar(closingCostAmount)}
					/>
				</Grid>
			</Grid>

			<AmorizationSchedule variant="printable" />

			<Typography level="title-lg">Expenses</Typography>
			<Grid container marginBottom={3}>
				<Grid xs={6}>
					<DataRow label="HOA Fees" value={formatDollar(hoaFees)} />
					<DataRow label="Insurance Fees" value={formatDollar(insuranceFee)} />
					<DataRow label="Other Expenses" value={formatDollar(otherExpenses)} />
					<DataRow
						label="Total Expenses"
						value={`$${expenseTotal.toFixed(2)}/month`}
					/>
				</Grid>
				<Grid xs={6}>
					<DataRow label="Property Tax" value={formatDollar(propertyTax)} />
					<DataRow
						label="Maintenance Fees"
						value={formatDollar(maintenanceFee)}
					/>
				</Grid>
			</Grid>

			<Stack direction={"row"}>
				<Typography level="title-lg">Rental Income</Typography>
				<Typography level="body-lg">{formatDollar(rentalIncome)}</Typography>
			</Stack>
			<Stack direction={"row"}>
				<Typography level="title-lg">Appreciation Rate</Typography>
				<Typography level="body-lg">{appreciationRate}</Typography>
			</Stack>
			<Stack direction={"row"}>
				<Typography level="title-lg">Cost To Sell</Typography>
				<Typography level="body-lg">{costToSell}</Typography>
			</Stack>
			<Breakdown variant="printable" />
		</>
	);
};
