import { FormLabel, Input, Stack } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Info, InfoType } from "./Info";

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
	function NumericFormatAdapter(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
			/>
		);
	}
);

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const DollarInput = ({
	variant,
	value,
	onChange,
	name,
	label,
	endDecorator,
	sx,
	info,
}: {
	name: string;
	label?: string;
	value: number;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	variant?: "plain";
	endDecorator?: React.ReactNode;
	sx?: SxProps;
	info?: string | InfoType;
}) => {
	const startDecorator = "$";
	return (
		<Stack>
			<Stack direction={"row"} alignItems={"center"} marginBottom={"6px"}>
				<FormLabel sx={{ fontSize: 14 }}>{label}</FormLabel>
				{info && <Info content={info} />}
			</Stack>
			<Input
				data-nosnippet
				variant={variant}
				name={name}
				value={value}
				startDecorator={startDecorator}
				onChange={onChange}
				slotProps={{
					input: {
						component: NumericFormatAdapter,
					},
				}}
				sx={sx}
				endDecorator={endDecorator}
			/>
		</Stack>
	);
};

export default DollarInput;
