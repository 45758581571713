import { Modal, ModalDialog, ModalClose, Typography } from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { formatDollar } from "../printable/PrintableState";
import { BreakdownListItem } from "./BreakdownListItem";

export const ROIBreakdownModal = ({
	year,
	open,
	onClose,
}: {
	year: number;
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput, breakdown } = useCalculatorContext();
	const { downPaymentAmount } = calculatorInput;
	const baseROI = breakdown[year - 1]?.baseROI;
	const annualCashFlow = breakdown[year - 1]?.annualCashFlow;

	if (year === 0) return null;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">Return on Investment @ Year {year}</Typography>
				<BreakdownListItem
					title="Cash Flow"
					data={formatDollar(annualCashFlow)}
				/>
				<BreakdownListItem
					title="Down Payment"
					data={formatDollar(downPaymentAmount)}
					operation="DIVIDE"
					divider
				/>
				<BreakdownListItem
					title="Return On Investment %"
					data={`${(baseROI * 100)?.toFixed(2)} %`}
				/>
			</ModalDialog>
		</Modal>
	);
};
