import { extendTheme } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
	interface TypographySystemOverrides {
		cool: true;
	}
}
const theme = extendTheme({
	components: {
		JoyInput: {
			styleOverrides: {
				root: {
					boxShadow: "none",
				},
			},
		},
		JoySelect: {
			styleOverrides: {
				root: {
					boxShadow: "none",
				},
			},
		},
	},
	typography: {
		cool: {
			fontFamily: "Roboto Mono",
			fontWeight: 200,
			// fontSize: "24px",
		},
	},
	colorSchemes: {
		light: {
			palette: {
				primary: {
					solidBg: "#0d6efd",
					solidBorder: "#0d6efd",
					solidHoverBg: "#0b5ed7",
					solidHoverBorder: "#0a58ca",
					solidActiveBg: "#0a58ca",
					solidActiveBorder: "#0a53be",
					solidDisabledBg: "#0d6efd",
					solidDisabledBorder: "#0d6efd",

					"50": "#fafafa",
					"100": "#f5f5f5",
					"200": "#e5e5e5",
					"300": "#d4d4d4",
					"400": "#a3a3a3",
					"500": "#737373",
					"600": "#525252",
					"700": "#404040",
					"800": "#262626",
					"900": "#171717",
				},
				background: {
					body: "var(--joy-palette-warning-softBg)",
				},
			},
		},
		dark: {
			palette: {
				primary: {
					solidBg: "#0d6efd",
					solidBorder: "#0d6efd",
					solidHoverBg: "#0b5ed7",
					solidHoverBorder: "#0a58ca",
					solidActiveBg: "#0a58ca",
					solidActiveBorder: "#0a53be",
					solidDisabledBg: "#0d6efd",
					solidDisabledBorder: "#0d6efd",

					"50": "#fafafa",
					"100": "#f5f5f5",
					"200": "#e5e5e5",
					"300": "#d4d4d4",
					"400": "#a3a3a3",
					"500": "#737373",
					"600": "#525252",
					"700": "#404040",
					"800": "#262626",
					"900": "#171717",
				},
				background: {
					body: "#1c1c1c",
					surface: "var(--joy-palette-primary-darkChannel)",
				},
			},
		},
	},
});

export default theme;
