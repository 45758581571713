import { Modal, ModalDialog, ModalClose, Typography } from "@mui/joy";

export const AboutThisModal = ({
	open,
	onClose,
}: {
	open: boolean;
	onClose: () => void;
}) => {
	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				maxWidth={500}
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">about this</Typography>
				<Typography level="title-sm">
					Simple. Mobile-Friendly. Transparent.
				</Typography>
				<Typography level="body-sm">
					Our platform is designed with a mobile-first approach, ensuring that
					you have the best experience, regardless of the device you use. We
					stand firmly against unnecessary complexities and upsells – what you
					see is what you get.
				</Typography>
				<Typography level="title-sm">Privacy by Design</Typography>
				<Typography level="body-sm">
					Your privacy is not just a priority; it's a principle. We do not store
					any of your data. There's no hidden database or sneaky data retention.
					Our unique approach means that all the information is embedded
					directly within the shared URL, which is decoded upon access. Your
					data remains yours, always.
				</Typography>
				<Typography level="title-sm">
					Empowering Your Property Decisions
				</Typography>

				<Typography level="body-sm">
					Born out of frustration with the clutter of misleading tools, we offer
					a straightforward, free tool to help you estimate the financial
					aspects of property purchases. While we provide a clear, user-friendly
					interface to simplify your decision-making process, we encourage users
					to seek professional financial advice for comprehensive and
					personalized guidance.
				</Typography>
				<Typography level="body-sm">
					It is not a substitute for professional financial advice.
				</Typography>
			</ModalDialog>
		</Modal>
	);
};
