import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Modal,
	ModalDialog,
	ModalClose,
	Stack,
	Typography,
	Box,
} from "@mui/joy";
import Markdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

export type InfoType = {
	title: string;
	description: string;
};
export const Info = ({ content }: { content?: string | InfoType }) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Box onClick={() => setShowModal(true)} display={"flex"}>
				<InfoOutlinedIcon
					sx={{ fontSize: 16 }}
					style={{
						marginLeft: 4,
					}}
				/>
			</Box>
			{typeof content === "string" ? (
				<Modal open={showModal} onClose={() => setShowModal(false)}>
					<ModalDialog
						variant="soft"
						size="md"
						maxWidth={500}
						sx={(theme) => ({
							[theme.breakpoints.only("xs")]: {
								top: "unset",
								bottom: 0,
								left: 0,
								right: 0,
								borderRadius: 0,
								transform: "none",
								maxWidth: "unset",
							},
						})}
					>
						<ModalClose />
						<Stack marginTop={2}>
							<Typography level="body-sm">
								<Markdown
									remarkPlugins={[remarkMath]}
									rehypePlugins={[[rehypeKatex, { output: "mathml" }]]}
								>
									{content}
								</Markdown>
							</Typography>
						</Stack>
					</ModalDialog>
				</Modal>
			) : (
				<Modal open={showModal} onClose={() => setShowModal(false)}>
					<ModalDialog
						variant="soft"
						size="md"
						maxWidth={500}
						sx={(theme) => ({
							[theme.breakpoints.only("xs")]: {
								top: "unset",
								bottom: 0,
								left: 0,
								right: 0,
								borderRadius: 0,
								transform: "none",
								maxWidth: "unset",
							},
						})}
					>
						<ModalClose />
						<Stack marginTop={2}>
							<Typography level="body-sm">
								<Typography level="cool">{content?.title}</Typography>
								<Markdown
									remarkPlugins={[remarkMath]}
									rehypePlugins={[[rehypeKatex, { output: "mathml" }]]}
								>
									{content?.description}
								</Markdown>
							</Typography>
						</Stack>
					</ModalDialog>
				</Modal>
			)}
		</>
	);
};
