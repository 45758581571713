import React from "react";
import { Box, Stack, Typography } from "@mui/joy";
import DollarInput from "./DollarInput";
import PercentInput from "./PercentInput";
import { useCalculatorContext } from "./CalculatorContext";
import { InfoText } from "./InfoText";

const RentalInput = () => {
	const { calculatorInput, setCalculatorInput } = useCalculatorContext();
	const {
		rentalIncome,
		rentalIncomeIncrease,
		mortgagePaymentAmount,
		hoaFees,
		propertyTax,
		cashFlow,
	} = calculatorInput;

	return (
		<Box marginY={4}>
			<Typography level="h4" data-nosnippet>
				Rental Data
			</Typography>
			<Stack marginTop={1} spacing={"12px"}>
				<Box display={"flex"}>
					<Box flex={2} marginRight={"6px"}>
						<DollarInput
							label="Rental Income"
							name="rentalIncome"
							value={rentalIncome}
							onChange={(e) => {
								setCalculatorInput({
									...calculatorInput,
									rentalIncome: Number(e.target.value),
								});
							}}
							info={InfoText.rentalData.rentalIncome}
						/>
					</Box>
					<Box flex={1}>
						<PercentInput
							label="Annual Increase"
							name="rentalIncomeIncrease"
							value={rentalIncomeIncrease}
							onChange={(e) => {
								setCalculatorInput({
									...calculatorInput,
									rentalIncomeIncrease: Number(e.target.value),
								});
							}}
						/>
					</Box>
				</Box>
				<Typography level="title-md">
					Cash Flow: ${cashFlow.toFixed(2)}
					/month
				</Typography>
			</Stack>
		</Box>
	);
};

export default RentalInput;
