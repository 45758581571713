import { createContext, useContext, useEffect, useState } from "react";
import {
	calculateAmortization,
	calculateBreakdown,
	calculateMortgage,
} from "./utils";
import { decodeUrlToState, encodeStateToUrl } from "./encodeUrl";
import { AmortizationScheduleType } from "./AmortizationSchedule";
import { BreakdownType } from "./breakdown/Breakdown";

export type Calculator = {
	// Purchase Data
	purchasePrice: number;
	downPaymentAmount: number;
	downPaymentPercent: number;
	interestRate: number;
	loanTerm: string;
	closingCostAmount: number;
	closingCostPercent: number;
	// Rental Data
	rentalIncome: number;
	rentalIncomeIncrease: number;
	mortgagePaymentAmount: number;
	// Other Expenses
	hoaFees: number;
	hoaFeesAnnualIncrease: number;
	propertyTax: number;
	propertyTaxAnnualIncrease: number;
	otherExpenses: number;
	otherExpensesAnnualIncrease: number;
	insuranceFee: number;
	insuranceFeeAnnualIncrease: number;
	maintenanceFee: number;
	maintenanceFeeAnnualIncrease: number;
	//
	cashFlow: number;
	// Sell Info
	appreciationRate: number;
	costToSell: number;
	// Share Info
	preparedFor?: string;
	propertyname?: string;
	//
	printableMode?: boolean;
};

export type CalculatorContextType = {
	calculatorInput: Calculator;
	setCalculatorInput: React.Dispatch<React.SetStateAction<Calculator>>;
	amortizationSchedule: AmortizationScheduleType[];
	breakdown: BreakdownType[];
};
export const CalculatorContext = createContext<
	CalculatorContextType | undefined
>(undefined);

export const CalculatorProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [calculatorInput, setCalculatorInput] = useState<Calculator>({
		// Purchase Data
		purchasePrice: 900000,
		downPaymentAmount: 180000,
		downPaymentPercent: 20,
		interestRate: 6.5,
		loanTerm: "30-fixed",
		closingCostAmount: 0,
		closingCostPercent: 0,
		// Rental Data
		rentalIncome: 3500,
		rentalIncomeIncrease: 2,
		mortgagePaymentAmount: 0,
		// Other Expenses
		hoaFees: 400,
		hoaFeesAnnualIncrease: 2,
		propertyTax: 400,
		propertyTaxAnnualIncrease: 2,
		otherExpenses: 100,
		otherExpensesAnnualIncrease: 2,
		insuranceFee: 100,
		insuranceFeeAnnualIncrease: 2,
		maintenanceFee: 200,
		maintenanceFeeAnnualIncrease: 2,
		// Sell Info
		appreciationRate: 2,
		costToSell: 8,
		//
		cashFlow: 0,
		//
		printableMode: false,
	});

	const [amortizationSchedule, setAmortizationSchedule] = useState<
		AmortizationScheduleType[]
	>([]);

	const [breakdown, setBreakdown] = useState<BreakdownType[]>([]);

	const {
		purchasePrice,
		downPaymentAmount,
		interestRate,
		loanTerm,
		rentalIncome,
		mortgagePaymentAmount,
		hoaFees,
		propertyTax,
		maintenanceFee,
		insuranceFee,
		otherExpenses,
		appreciationRate,
	} = calculatorInput;

	/**
	 * URL Share Logic
	 */
	useEffect(() => {
		encodeStateToUrl({ calculatorState: calculatorInput });
		const mortgageAmount = calculateMortgage({
			principal: purchasePrice - downPaymentAmount,
			rate: interestRate,
			loanTerm,
		});

		setCalculatorInput({
			...calculatorInput,
			mortgagePaymentAmount: mortgageAmount,
		});
	}, [purchasePrice, downPaymentAmount, interestRate, loanTerm]);

	useEffect(() => {
		const pathname = window.location.pathname.slice(1);
		if (pathname.length > 1) {
			const state = decodeUrlToState({ pathname });
			setCalculatorInput(state);
		}
	}, []);

	useEffect(() => {
		const cashFlow =
			rentalIncome -
			mortgagePaymentAmount -
			hoaFees -
			propertyTax -
			maintenanceFee -
			insuranceFee -
			otherExpenses;

		setCalculatorInput((calculatorInput) => ({
			...calculatorInput,
			cashFlow,
		}));
	}, [
		rentalIncome,
		mortgagePaymentAmount,
		hoaFees,
		propertyTax,
		maintenanceFee,
		insuranceFee,
		otherExpenses,
	]);

	useEffect(() => {
		const amortizationSchedule = calculateAmortization({
			principal: purchasePrice - downPaymentAmount,
			loanTerm,
			rate: interestRate,
			monthlyPayment: mortgagePaymentAmount,
		});

		setAmortizationSchedule(amortizationSchedule);
	}, [
		purchasePrice,
		downPaymentAmount,
		interestRate,
		loanTerm,
		mortgagePaymentAmount,
	]);

	useEffect(() => {
		const breakdown = calculateBreakdown({
			amortizationSchedule,
			appreciationRate,
			calculatorData: calculatorInput,
		});
		setBreakdown(breakdown);
	}, [amortizationSchedule, appreciationRate, calculatorInput]);

	return (
		<CalculatorContext.Provider
			value={{
				calculatorInput,
				setCalculatorInput,
				amortizationSchedule,
				breakdown,
			}}
		>
			{children}
		</CalculatorContext.Provider>
	);
};

export const useCalculatorContext = () => {
	const calcultorContext = useContext(CalculatorContext);
	if (calcultorContext === undefined) {
		throw new Error("undefined");
	}
	return calcultorContext;
};
