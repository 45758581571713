import {
	Modal,
	ModalDialog,
	ModalClose,
	Typography,
	Stack,
	Divider,
} from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { formatDollar } from "../printable/PrintableState";

export const ReturnOnSaleBreakdownModal = ({
	year,
	open,
	onClose,
}: {
	year: number;
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput, breakdown } = useCalculatorContext();
	const { downPaymentAmount } = calculatorInput;

	const cashAfterSell = breakdown[year - 1]?.cashAfterSell;

	if (year === 0) return null;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">Return On Sale @ Year {year}</Typography>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">Cash After Sell</Typography>
					<Typography level="body-sm">{formatDollar(cashAfterSell)}</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">Down Payment</Typography>
					<Stack marginLeft={2}>
						<Stack direction={"row"} justifyContent={"end"} marginBottom={1}>
							<Typography level="body-lg" marginRight={3} marginTop={"-3px"}>
								-
							</Typography>
							<Typography level="body-sm">
								{formatDollar(downPaymentAmount)}
							</Typography>
						</Stack>
						<Divider />
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm"> </Typography>
					<Stack marginBottom={1}>
						<Typography level="body-sm">
							{formatDollar(cashAfterSell - downPaymentAmount)}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">Down Payment</Typography>
					<Stack marginLeft={2}>
						<Stack direction={"row"} justifyContent={"end"} marginBottom={1}>
							<Typography level="body-lg" marginRight={3} marginTop={"-3px"}>
								÷
							</Typography>
							<Typography level="body-sm">
								{formatDollar(downPaymentAmount)}
							</Typography>
						</Stack>
						<Divider />
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">% Return after Sale</Typography>
					<Stack marginBottom={1}>
						<Typography level="body-sm">
							{`${(
								((cashAfterSell - downPaymentAmount) / downPaymentAmount) *
								100
							).toFixed(2)} %`}
						</Typography>
					</Stack>
				</Stack>
			</ModalDialog>
		</Modal>
	);
};
