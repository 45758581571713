import {
	Box,
	IconButton,
	Sheet,
	Stack,
	Switch,
	Table,
	Typography,
} from "@mui/joy";
import { Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PercentInput from "../PercentInput";
import { useCalculatorContext } from "../CalculatorContext";
import BreakdownTable from "./BreakdownTable";
import BreakdownChart from "./BreakdownChart";
import { InfoText } from "../InfoText";

export type BreakdownType = {
	year: number;
	baseROI: number;
	principalPaid: number;
	equityAccumulated: number;
	houseValue: number;
	annualCashFlow: number;
	accumulatedAnnualCashFlow: number;
	cashAfterSell: number;
	costsToSellAmount: number;
};
const Breakdown = ({ variant }: { variant?: "printable" }) => {
	const { calculatorInput, breakdown, setCalculatorInput } =
		useCalculatorContext();
	const { costToSell, appreciationRate } = calculatorInput;

	const [tableMode, setTableMode] = useState(false);

	const [isToggled, setIsToggled] = useState(true);

	const printMode = variant === "printable";

	useEffect(() => {
		if (printMode) {
			setIsToggled(true);
		}
	}, [printMode]);

	return (
		<>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Typography level="h4" data-nosnippet>
					Sale Data
				</Typography>
				{!printMode && (
					<IconButton
						aria-label="expand row"
						variant="plain"
						color="neutral"
						size="lg"
						onClick={() => setIsToggled(!isToggled)}
					>
						{isToggled ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				)}
			</Stack>
			<Collapse in={isToggled}>
				{!printMode && (
					<Box display={"flex"} marginBottom={"20px"}>
						<Box flex={1} marginRight={"6px"}>
							<PercentInput
								label="Appreciation Rate / yr"
								name="appreciationRate"
								value={appreciationRate}
								onChange={(e) =>
									setCalculatorInput({
										...calculatorInput,
										appreciationRate: Number(e.target.value),
									})
								}
								info={InfoText.saleData.appreciationRate}
							/>
						</Box>
						<Box flex={1}>
							<PercentInput
								label="Cost to Sell"
								name="costToSell"
								value={costToSell}
								onChange={(e) =>
									setCalculatorInput({
										...calculatorInput,
										costToSell: Number(e.target.value),
									})
								}
								info={InfoText.saleData.costToSell}
							/>
						</Box>
					</Box>
				)}
				{/* {!printMode && (
					<Box display="flex" justifyContent={"end"} marginBottom={2}>
						<Switch
							color={"primary"}
							slotProps={{ input: { "aria-label": "tableMode" } }}
							startDecorator={<Typography level="title-sm">Table</Typography>}
							endDecorator={<Typography level="title-sm">Chart</Typography>}
							checked={tableMode}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setTableMode(event.target.checked)
							}
						/>
					</Box>
				)} */}
				<BreakdownTable printMode={printMode} breakdown={breakdown} />

				{/* {!tableMode ? (
					<BreakdownTable printMode={printMode} breakdown={breakdown} />
				) : (
					<BreakdownChart />
				)} */}
			</Collapse>
		</>
	);
};

export default Breakdown;
