import {
	Modal,
	ModalDialog,
	ModalClose,
	Typography,
	Grid,
	Stack,
	Divider,
} from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { formatDollar } from "../printable/PrintableState";

export const CashFlowBreakdownModal = ({
	year,
	open,
	onClose,
}: {
	year: number;
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput } = useCalculatorContext();
	const {
		mortgagePaymentAmount,
		rentalIncome,
		rentalIncomeIncrease,
		hoaFees,
		hoaFeesAnnualIncrease,
		maintenanceFee,
		maintenanceFeeAnnualIncrease,
		insuranceFee,
		insuranceFeeAnnualIncrease,
		otherExpenses,
		otherExpensesAnnualIncrease,
		propertyTax,
		propertyTaxAnnualIncrease,
	} = calculatorInput;

	const annualCashFlow =
		(rentalIncome * Math.pow(1 + rentalIncomeIncrease / 100, year - 1) -
			mortgagePaymentAmount -
			hoaFees * Math.pow(1 + hoaFeesAnnualIncrease / 100, year - 1) -
			maintenanceFee *
				Math.pow(1 + maintenanceFeeAnnualIncrease / 100, year - 1) -
			insuranceFee * Math.pow(1 + insuranceFeeAnnualIncrease / 100, year - 1) -
			otherExpenses *
				Math.pow(1 + otherExpensesAnnualIncrease / 100, year - 1) -
			propertyTax * Math.pow(1 + propertyTaxAnnualIncrease / 100, year - 1)) *
		12;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				style={{ maxWidth: 400, zIndex: 1000 }}
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">Cash Flow @ Year {year}</Typography>

				<Grid container>
					<Grid xs={6}>
						<Typography level="body-sm">Rental Income</Typography>
						<Typography level="body-sm">Mortgage</Typography>
						<Typography level="body-sm">HOA Fees</Typography>
						<Typography level="body-sm">Property Tax</Typography>
						<Typography level="body-sm">Insurance Fees</Typography>
						<Typography level="body-sm">Other Expenses</Typography>
					</Grid>

					<Grid xs={6} textAlign={"right"}>
						<Stack marginBottom={1}>
							<Typography level="body-sm">
								{formatDollar(
									rentalIncome *
										Math.pow(1 + rentalIncomeIncrease / 100, year - 1) *
										12
								)}
							</Typography>
							<Typography level="body-sm">
								{formatDollar(mortgagePaymentAmount * 12)}
							</Typography>
							<Typography level="body-sm">
								{formatDollar(
									hoaFees *
										Math.pow(1 + hoaFeesAnnualIncrease / 100, year - 1) *
										12
								)}
							</Typography>

							<Typography level="body-sm">
								{formatDollar(
									propertyTax *
										Math.pow(1 + propertyTaxAnnualIncrease / 100, year - 1) *
										12
								)}
							</Typography>
							<Typography level="body-sm">
								{formatDollar(
									insuranceFee *
										Math.pow(1 + insuranceFeeAnnualIncrease / 100, year - 1) *
										12
								)}
							</Typography>
							<Stack direction={"row"} justifyContent={"end"}>
								<Typography level="body-lg" marginRight={3} marginTop={"-3px"}>
									-
								</Typography>
								<Typography level="body-sm">
									{`${formatDollar(
										otherExpenses *
											Math.pow(
												1 + otherExpensesAnnualIncrease / 100,
												year - 1
											) *
											12
									)}`}
								</Typography>
							</Stack>
						</Stack>
						<Divider />
					</Grid>
				</Grid>
				<Grid container marginBottom={3}>
					<Grid xs={6}>
						<Typography level="body-sm">Cash Flow</Typography>{" "}
					</Grid>
					<Grid xs={6} textAlign={"right"}>
						<Stack marginBottom={1}>
							<Typography level="body-sm">
								{formatDollar(annualCashFlow)}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</ModalDialog>
		</Modal>
	);
};
