import "./App.css";
import { Box, Button, Container, Divider, Stack, Typography } from "@mui/joy";
import AmorizationSchedule from "./AmortizationSchedule";
import Breakdown from "./breakdown/Breakdown";
import BreakdownChart from "./breakdown/BreakdownChart";
import BasicPropertyInput from "./BasicPropertyInput";
import RentalInput from "./RentalInput";
import { useCalculatorContext } from "./CalculatorContext";
import OtherExpensesInput from "./OtherExpensesInput";
import { useState } from "react";
import ShareModal from "./modal/ShareModal";
import { PrintableState } from "./printable/PrintableState";
import { AboutThisModal } from "./modal/AboutThisModal";

function App() {
	const { calculatorInput } = useCalculatorContext();
	const { printableMode } = calculatorInput;

	const [openModal, setOpenModal] = useState(false);
	const [openAboutThisModal, setOpenAboutThisModal] = useState(false);
	return (
		<>
			{printableMode ? (
				<Container maxWidth="md" style={{ marginBottom: 20 }}>
					<PrintableState />
				</Container>
			) : (
				<Container maxWidth="md" style={{ marginBottom: 20 }}>
					<Stack
						flexDirection="row"
						justifyContent={"space-between"}
						marginTop={2}
					>
						<Stack direction={"row"}>
							<Typography level="h2" margin={0}>
								cozy burrow
							</Typography>
							<Typography level="cool" margin={0} fontSize={10}>
								ALPHA
							</Typography>
						</Stack>
						<Button variant="plain" onClick={() => setOpenAboutThisModal(true)}>
							<Typography level="cool">about this</Typography>
						</Button>
						<AboutThisModal
							open={openAboutThisModal}
							onClose={() => setOpenAboutThisModal(false)}
						/>
					</Stack>
					<BasicPropertyInput />

					<AmorizationSchedule />

					<OtherExpensesInput />

					<RentalInput />

					<Breakdown />

					<Button
						variant="solid"
						color="primary"
						size="lg"
						onClick={() => setOpenModal(true)}
						style={{
							margin: "20px 0px",
							marginBottom: 40,
							float: "right",
						}}
					>
						Share
					</Button>
					<ShareModal open={openModal} onClose={() => setOpenModal(false)} />
				</Container>
			)}
		</>
	);
}

export default App;
