import { IconButton, Sheet, Stack, Table, Typography } from "@mui/joy";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import { nf } from "./utils";
import { useCalculatorContext } from "./CalculatorContext";
import { InfoText } from "./InfoText";
import { Info } from "./Info";

export type AmortizationScheduleMonth = {
	month: number;
	balance: number;
	principalPaid: number;
	interestPaid: number;
	totalInterestPaid: number;
};

export type AmortizationScheduleType = {
	year: number;
	totalInterestPaid: number;
	principalPaid: number;
	accumulatedPrincipalPaid: number;
	balance: number;
	monthlyPayments: AmortizationScheduleMonth[];
};

function Row(props: {
	row: AmortizationScheduleType;
	initialOpen?: boolean;
	printMode?: boolean;
}) {
	const { row, initialOpen, printMode } = props;
	const [open, setOpen] = useState(initialOpen || false);

	return (
		<>
			<tr>
				<td style={{ width: 50 }}>{row.year}</td>
				<td>{nf.format(row.totalInterestPaid)}</td>
				<td>{nf.format(row.principalPaid)}</td>
				<td>{nf.format(row.balance)}</td>
				<td>
					{!printMode && (
						<IconButton
							aria-label="expand row"
							variant="plain"
							color="neutral"
							size="md"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</td>
			</tr>
			<tr>
				<td style={{ height: 0, padding: 0 }} colSpan={5}>
					{open && (
						<Sheet
							variant="soft"
							sx={{
								p: 1,
								boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)",
								overflow: "auto",
							}}
						>
							<Typography level="body-sm" component="div">
								Monthly Breakdown
							</Typography>
							<Table
								borderAxis="bothBetween"
								size="sm"
								sx={{
									"& > thead > tr > th, & > tbody > tr > td": {
										textAlign: "center",
									},
									"--TableCell-paddingX": "0.5rem",
								}}
							>
								<thead>
									<tr>
										<th style={{ width: 50 }}>Month</th>
										<th>Interest Paid</th>
										<th>Principal Paid</th>
										<th>Balance</th>
									</tr>
								</thead>
								<tbody>
									{row.monthlyPayments.map((monthlyPayment) => (
										<tr key={monthlyPayment.month}>
											<td>{monthlyPayment.month}</td>
											<td>{nf.format(monthlyPayment.interestPaid)}</td>
											<td>{nf.format(monthlyPayment.principalPaid)}</td>
											<td>{nf.format(monthlyPayment.balance)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Sheet>
					)}
				</td>
			</tr>
		</>
	);
}

const AmorizationSchedule = ({ variant }: { variant?: "printable" }) => {
	const [isToggled, setIsToggled] = useState(false);
	const { amortizationSchedule } = useCalculatorContext();

	const printMode = variant === "printable";

	useEffect(() => {
		if (printMode) {
			setIsToggled(true);
		}
	}, [printMode]);

	return (
		<>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Typography level="title-lg">Amortization Schedule</Typography>
					<Info content={InfoText.purchaseData.amortizationSchedule} />
				</Stack>
				{!printMode && (
					<IconButton
						aria-label="expand row"
						variant="plain"
						color="neutral"
						size="lg"
						onClick={() => setIsToggled(!isToggled)}
					>
						{isToggled ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				)}
			</Stack>
			<Collapse in={isToggled} timeout={500}>
				<Sheet sx={!printMode ? { overflow: "auto", height: 500 } : {}}>
					<Table
						size="sm"
						aria-label="collapsible table"
						sx={{
							"& > thead > tr > th, & > tbody > tr > td": {
								textAlign: "center",
							},
							'& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
								{
									borderBottom: 0,
								},
						}}
					>
						<thead>
							<tr>
								<th style={{ width: 50 }}>Year</th>
								<th>Interest Paid</th>
								<th>Principal Paid</th>
								<th>Balance Remaining</th>
								<th style={{ width: "40px" }}></th>
							</tr>
						</thead>
						<tbody>
							{amortizationSchedule.map((row, index) => (
								<Row key={row.year} row={row} printMode={printMode} />
							))}
						</tbody>
					</Table>
				</Sheet>
			</Collapse>
		</>
	);
};

export default AmorizationSchedule;
