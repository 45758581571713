import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CalculatorProvider } from "./CalculatorContext";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import theme from "./Theme";
import {
	experimental_extendTheme as materialExtendTheme,
	Experimental_CssVarsProvider as MaterialCssVarsProvider,
	THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const materialTheme = materialExtendTheme();

root.render(
	<MaterialCssVarsProvider
		theme={{ [MATERIAL_THEME_ID]: materialTheme }}
		defaultMode="dark"
	>
		<CssVarsProvider theme={theme} defaultMode="dark">
			<CssBaseline />
			<CalculatorProvider>
				<App />
				<SpeedInsights />
				<Analytics />
			</CalculatorProvider>
		</CssVarsProvider>
	</MaterialCssVarsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
