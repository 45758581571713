import { Grid, Stack, Typography } from "@mui/joy";

export const DataRow = ({ label, value }: { label: string; value: string }) => {
	return (
		<Grid container>
			<Grid xs={8}>
				<Typography level="title-sm">{label}:</Typography>
			</Grid>
			<Grid xs={4}>
				<Typography level="title-sm">{value}</Typography>
			</Grid>
		</Grid>
	);
};
