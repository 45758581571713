import { Button, Sheet, Stack, Table, Typography } from "@mui/joy";
import { nf } from "../utils";
import { BreakdownType } from "./Breakdown";
import { useCalculatorContext } from "../CalculatorContext";
import { Info } from "../Info";
import { InfoText } from "../InfoText";
import { useState } from "react";
import { CashFlowBreakdownModal } from "./CashFlowBreakdownModal";
import { EquityAccumulatedBreakdownModal } from "./EquityAccumulatedBreakdownModal";
import { CashEarnedOnSaleBreakdownModal } from "./CashEarnedOnSaleBreakdownModal";
import { ReturnOnSaleBreakdownModal } from "./ReturnOnSaleBreakdownModal";
import { CostToSellBreakdownModal } from "./CostToSellBreakdownModal";
import { ROIBreakdownModal } from "./ROIBreakdownModal";

const BreakdownTable = ({
	printMode,
	breakdown,
}: {
	printMode: boolean;
	breakdown: BreakdownType[];
}) => {
	const { calculatorInput } = useCalculatorContext();
	const [openCashFlowBreakdownModal, setCashFlowBreakdownModal] =
		useState(false);
	const [openROIBreakdownModal, setROIBreakdownModal] = useState(false);
	const [openEquityAccumulatedModal, setEquityAccumulatedModal] =
		useState(false);
	const [openCashEarnedOnSaleModal, setCashEarnedOnSaleModal] = useState(false);
	const [openCostToSellModal, setCostToSellModal] = useState(false);
	const [openReturnOnSaleModal, setReturnOnSaleModal] = useState(false);

	const [modalData, setModalData] = useState({ year: 0 });

	const { purchasePrice, downPaymentAmount, closingCostAmount, costToSell } =
		calculatorInput;

	return (
		<>
			<Sheet sx={!printMode ? { overflow: "auto", height: 500 } : {}}>
				<Table
					size="sm"
					aria-label="collapsible table"
					stickyHeader
					sx={{
						"& tr > *:first-child": {
							position: "sticky",
							left: 0,
							boxShadow: "1px 0 var(--TableCell-borderColor)",
							backgroundColor: "#1c1c1c",
						},
						"& > thead > tr > th, & > tbody > tr > td": {
							textAlign: "center",
							backgroundColor: "#1c1c1c",
						},
						"& >thead > tr > th": {
							backgroundColor: "#1c1c1c",
						},
						overflow: "auto",
					}}
				>
					<thead>
						<tr>
							<th style={{ width: 50, flexDirection: "row" }}>Year</th>
							<th style={{ width: 100 }}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography>Cash Flow</Typography>
									<Info content={InfoText.breakdownTable.cashFlow} />
								</Stack>
							</th>
							<th style={{ width: 100 }}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography>ROI</Typography>
									<Info content={InfoText.breakdownTable.cashFlow} />
								</Stack>
							</th>
							<th style={{ width: 150 }}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography>Equity Accumulated</Typography>
									<Info content={InfoText.breakdownTable.equityAccumulated} />
								</Stack>
							</th>
							<th style={{ width: 100 }}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography>Costs To Sell</Typography>
									<Info content={InfoText.breakdownTable.equityAccumulated} />
								</Stack>
							</th>
							<th style={{ width: 150 }}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography>Cash Earned On Sell</Typography>
									<Info content={InfoText.breakdownTable.equityAccumulated} />
								</Stack>
							</th>
							<th style={{ width: 150 }}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Typography>% Return on Sale</Typography>
									<Info content={InfoText.breakdownTable.equityAccumulated} />
								</Stack>
							</th>
						</tr>
					</thead>
					<tbody>
						{breakdown.map((row, idx) => {
							const {
								baseROI,
								annualCashFlow,
								equityAccumulated,
								cashAfterSell,
								costsToSellAmount,
							} = row;

							return (
								<>
									<tr key={row.year}>
										<td style={{ zIndex: 2 }}>{row.year}</td>
										<td>
											<Button
												variant="plain"
												size="sm"
												onClick={() => {
													setCashFlowBreakdownModal(true);
													setModalData({ year: row.year });
												}}
												fullWidth
												style={{ margin: 0 }}
											>
												<Typography
													level="body-sm"
													fontSize={12}
													whiteSpace={"nowrap"}
												>
													{nf.format(annualCashFlow)}
												</Typography>
											</Button>
										</td>
										<td>
											<Button
												variant="plain"
												size="sm"
												onClick={() => {
													setROIBreakdownModal(true);
													setModalData({ year: row.year });
												}}
												fullWidth
												style={{ margin: 0 }}
											>
												<Typography
													level="body-sm"
													fontSize={12}
													whiteSpace={"nowrap"}
												>
													{(baseROI * 100).toFixed(2) + "%"}
												</Typography>
											</Button>
										</td>
										<td>
											<Button
												variant="plain"
												size="sm"
												onClick={() => {
													setEquityAccumulatedModal(true);
													setModalData({ year: row.year });
												}}
												fullWidth
												style={{ margin: 0 }}
											>
												<Typography
													level="body-sm"
													fontSize={12}
													whiteSpace={"nowrap"}
												>
													{nf.format(equityAccumulated)}
												</Typography>
											</Button>
										</td>
										<td>
											<Button
												variant="plain"
												size="sm"
												onClick={() => {
													setCostToSellModal(true);
													setModalData({ year: row.year });
												}}
												fullWidth
												style={{ margin: 0 }}
											>
												<Typography
													level="body-sm"
													fontSize={12}
													whiteSpace={"nowrap"}
												>
													{nf.format(costsToSellAmount)}
												</Typography>
											</Button>
										</td>
										<td>
											{
												<Button
													variant="plain"
													size="sm"
													onClick={() => {
														setCashEarnedOnSaleModal(true);
														setModalData({ year: row.year });
													}}
													fullWidth
													style={{ margin: 0 }}
												>
													<Typography
														level="body-sm"
														fontSize={12}
														whiteSpace={"nowrap"}
													>
														{nf.format(cashAfterSell)}
													</Typography>
												</Button>
											}
										</td>
										<td>
											{
												<Button
													variant="plain"
													size="sm"
													onClick={() => {
														setReturnOnSaleModal(true);
														setModalData({ year: row.year });
													}}
													fullWidth
													style={{ margin: 0 }}
												>
													<Typography
														level="body-sm"
														fontSize={12}
														whiteSpace={"nowrap"}
													>
														{(
															((cashAfterSell - downPaymentAmount) /
																downPaymentAmount) *
															100
														).toFixed(2) + "%"}
													</Typography>
												</Button>
											}
										</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</Table>
			</Sheet>
			<CashFlowBreakdownModal
				open={openCashFlowBreakdownModal}
				onClose={() => setCashFlowBreakdownModal(false)}
				year={modalData.year}
			/>
			<ROIBreakdownModal
				open={openROIBreakdownModal}
				onClose={() => setROIBreakdownModal(false)}
				year={modalData.year}
			/>
			<EquityAccumulatedBreakdownModal
				open={openEquityAccumulatedModal}
				onClose={() => setEquityAccumulatedModal(false)}
				year={modalData.year}
			/>
			<CashEarnedOnSaleBreakdownModal
				open={openCashEarnedOnSaleModal}
				onClose={() => setCashEarnedOnSaleModal(false)}
				year={modalData.year}
			/>
			<CostToSellBreakdownModal
				open={openCostToSellModal}
				onClose={() => setCostToSellModal(false)}
				year={modalData.year}
			/>
			<ReturnOnSaleBreakdownModal
				open={openReturnOnSaleModal}
				onClose={() => setReturnOnSaleModal(false)}
				year={modalData.year}
			/>
		</>
	);
};

export default BreakdownTable;
