import {
	Stack,
	Divider,
	FormLabel,
	Select,
	Typography,
	Option,
	Box,
} from "@mui/joy";
import DollarInput from "./DollarInput";
import PercentInput from "./PercentInput";
import { ChangeEvent } from "react";
import { useCalculatorContext } from "./CalculatorContext";
import { InfoText } from "./InfoText";
import { Info } from "./Info";

const BasicPropertyInput = ({ variant }: { variant?: "printable" }) => {
	const { calculatorInput, setCalculatorInput } = useCalculatorContext();
	const {
		purchasePrice,
		downPaymentAmount,
		downPaymentPercent,
		closingCostAmount,
		closingCostPercent,
		interestRate,
		loanTerm,
		mortgagePaymentAmount,
	} = calculatorInput;

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCalculatorInput({
			...calculatorInput,
			[event.target.name]: event.target.value,
		});
	};

	return (
		<Box marginY={5}>
			<Typography level="h4" data-nosnippet>
				Home Purchase Data
			</Typography>
			<Stack marginTop={1} spacing={"12px"}>
				<DollarInput
					label="Purchase Price"
					name="purchasePrice"
					value={purchasePrice}
					onChange={(e) => {
						const downPaymentAmount =
							Number(e.target.value) * (downPaymentPercent / 100);
						setCalculatorInput({
							...calculatorInput,
							purchasePrice: Number(e.target.value),
							downPaymentAmount,
						});
					}}
					info={InfoText.purchaseData.purchasePrice}
				/>
				<DollarInput
					label="Down Payment"
					name="downPaymentAmount"
					value={downPaymentAmount}
					onChange={(e) => {
						const downPaymentPercent =
							(Number(e.target.value) / purchasePrice) * 100;
						setCalculatorInput({
							...calculatorInput,
							downPaymentAmount: Number(e.target.value),
							downPaymentPercent,
						});
					}}
					info={InfoText.purchaseData.downPayment}
					endDecorator={
						<>
							<Divider orientation="vertical" />
							<PercentInput
								variant="plain"
								name="downPaymentPercent"
								value={downPaymentPercent}
								onChange={(e) => {
									const downPaymentAmount =
										purchasePrice * (Number(e.target.value) / 100);
									setCalculatorInput({
										...calculatorInput,
										downPaymentAmount,
										downPaymentPercent: Number(e.target.value),
									});
								}}
								sx={{
									"&::before": {
										display: "none",
									},
								}}
							/>
						</>
					}
				/>
				<Box display={"flex"}>
					<Box flex={2} marginRight={"6px"}>
						<PercentInput
							label="Interest Rate"
							name="interestRate"
							value={interestRate}
							onChange={handleInputChange}
							info={InfoText.purchaseData.interestRate}
						/>
					</Box>
					<Box flex={1}>
						<Stack>
							<Stack
								direction={"row"}
								alignItems={"center"}
								marginBottom={"6px"}
							>
								<FormLabel>Loan Term</FormLabel>
								<Info content={InfoText.purchaseData.loanTerm} />
							</Stack>
							<Select
								name="loanTerm"
								defaultValue={loanTerm}
								onChange={(_, value) => {
									setCalculatorInput({
										...calculatorInput,
										loanTerm: value || "",
									});
								}}
							>
								<Option value="30-fixed">30 Year</Option>
								<Option value="15-fixed">15 Year</Option>
							</Select>
						</Stack>
					</Box>
				</Box>
				<DollarInput
					label="Closing Costs"
					name="closingCostAmount"
					value={closingCostAmount}
					onChange={(e) => {
						const closingCostPercent =
							(closingCostAmount / purchasePrice) * 100;
						setCalculatorInput({
							...calculatorInput,
							closingCostAmount: Number(e.target.value),
							closingCostPercent,
						});
					}}
					info={InfoText.purchaseData.closingCosts}
					endDecorator={
						<>
							<Divider orientation="vertical" />
							<PercentInput
								variant="plain"
								name="closingCostPercent"
								value={closingCostPercent}
								onChange={(e) => {
									const closingCostAmount =
										purchasePrice * (Number(e.target.value) / 100);
									setCalculatorInput({
										...calculatorInput,
										closingCostAmount,
										closingCostPercent: Number(e.target.value),
									});
								}}
								sx={{
									"&::before": {
										display: "none",
									},
								}}
							/>
						</>
					}
				/>
				<Stack flexDirection={"row"} alignItems={"center"}>
					<Typography level="title-md">
						Principal & Interest: ${mortgagePaymentAmount.toFixed(2)}/month
					</Typography>
					<Info content={InfoText.purchaseData.principleAndInterest} />
				</Stack>
			</Stack>
		</Box>
	);
};

export default BasicPropertyInput;
