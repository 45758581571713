import { FormLabel, Input, Stack } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Info, InfoType } from "./Info";

const PercentInput = ({
	variant,
	value,
	onChange,
	name,
	label,
	endDecorator,
	sx,
	info,
}: {
	name: string;
	label?: string;
	value: number;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	variant?: "plain";
	endDecorator?: React.ReactNode;
	sx?: SxProps;
	info?: string | InfoType;
}) => {
	const inputRef = React.useRef<HTMLInputElement | null>(null);

	const startDecorator = "%";
	return (
		<Stack>
			{label && (
				<Stack direction={"row"} alignItems={"center"} marginBottom={"6px"}>
					<FormLabel>{label}</FormLabel>
					{info && <Info content={info} />}
				</Stack>
			)}
			<Input
				type="number"
				variant={variant}
				name={name}
				value={value}
				startDecorator={startDecorator}
				onChange={onChange}
				sx={sx}
				slotProps={{
					input: {
						ref: inputRef,
						min: 0,
						max: 100,
						step: 0.1,
					},
				}}
				endDecorator={endDecorator}
			/>
		</Stack>
	);
};

export default PercentInput;
