import {
	Modal,
	ModalDialog,
	ModalClose,
	Typography,
	Stack,
	Divider,
} from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { formatDollar } from "../printable/PrintableState";
import Breakdown from "./Breakdown";
import { BreakdownListItem } from "./BreakdownListItem";

export const CostToSellBreakdownModal = ({
	year,
	open,
	onClose,
}: {
	year: number;
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput, breakdown } = useCalculatorContext();
	const { costToSell } = calculatorInput;
	const houseValue = breakdown[year - 1]?.houseValue;

	if (year === 0) return null;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">Cost To Sell @ Year {year}</Typography>
				<BreakdownListItem
					title="House Value"
					data={formatDollar(houseValue)}
				/>
				<BreakdownListItem
					title="Cost To Sell %"
					data={(costToSell / 100)?.toFixed(2)}
					operation="MULTIPLY"
					divider
				/>
				<BreakdownListItem
					title="Cost To Sell"
					data={formatDollar(houseValue * (costToSell / 100))}
				/>
			</ModalDialog>
		</Modal>
	);
};
