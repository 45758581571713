import {
	Modal,
	ModalDialog,
	ModalClose,
	Typography,
	Stack,
	Divider,
} from "@mui/joy";
import { useCalculatorContext } from "../CalculatorContext";
import { formatDollar } from "../printable/PrintableState";

export const EquityAccumulatedBreakdownModal = ({
	year,
	open,
	onClose,
}: {
	year: number;
	open: boolean;
	onClose: () => void;
}) => {
	const { calculatorInput, amortizationSchedule } = useCalculatorContext();
	const { downPaymentAmount } = calculatorInput;

	if (year === 0) return null;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				variant="soft"
				size="md"
				sx={(theme) => ({
					[theme.breakpoints.only("xs")]: {
						top: "unset",
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 0,
						transform: "none",
						maxWidth: "unset",
					},
				})}
			>
				<ModalClose />
				<Typography level="cool">Equity Accumulated @ Year {year}</Typography>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">Down Payment</Typography>
					<Typography level="body-sm">
						{formatDollar(downPaymentAmount)}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">
						Accumulated Principal Paid (from Mortgage)
					</Typography>
					<Stack marginLeft={2}>
						<Stack direction={"row"} justifyContent={"end"} marginBottom={1}>
							<Typography level="body-lg" marginRight={3} marginTop={"-3px"}>
								+
							</Typography>
							<Typography level="body-sm">
								{formatDollar(
									amortizationSchedule[year - 1]?.accumulatedPrincipalPaid
								)}
							</Typography>
						</Stack>
						<Divider />
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography level="body-sm">Equity Accumulated</Typography>
					<Stack marginBottom={1}>
						<Typography level="body-sm">
							{formatDollar(
								downPaymentAmount +
									amortizationSchedule[year - 1]?.accumulatedPrincipalPaid
							)}
						</Typography>
					</Stack>
				</Stack>
			</ModalDialog>
		</Modal>
	);
};
